body {
    --gray-extra-light: #e8e8e8;
    --white: white;
    --dark: #181c32;

	overflow-x: hidden;
}
.is-invalid ~ .invalid-feedback {
	position: absolute;
	width: 100%;
	max-width: 280px;
	display: inline-block;
}

.invalid-feedback {
	font-size: 0.725rem;
}

.version-app {
	position: fixed;
	left: 5px;

	&-bottom {
		bottom: 0;
	}

	&-top {
		top: 0;
	}
}

.hover-scroll-overlay-y {
	overflow-y: scroll !important;
	height: calc(100vh - 170px);
}

.page-item.active .page-link {
	color: #d3e2e2;
}

.modal-dialog {
	max-width: 900px;
	margin: 1.75rem auto;
	height: calc(100% - 46px);
	display: flex;
	align-items: center;
}

figure {
	margin: 0;
}

.symbol-circle {
	figure {
		border-radius: 50%;
		overflow: hidden;
		margin: 0;
	}
}

//.toast {
//	&-container {
//		.ngx-toastr {
//			border-left: 5px solid var(--blue-medium);
//			//border-radius: 0 3px 3px 0 !important;
//			color: var(--black) !important;
//			background: var(--white);
//			box-shadow: 0 2px 13px rgba(0, 0, 0, 0.27) !important;
//
//			&.toast-info {
//				border-color: var(--yellow);
//			}
//			&.toast-success {
//				border-color: var(--green);
//			}
//			&.toast-error {
//				border-color: var(--red);
//			}
//
//			.toast-title {
//				font-weight: 600;
//				color: var(--gray);
//			}
//
//			.toast-message {
//				font-weight: 300;
//				color: var(--gray);
//			}
//
//			button {
//				color: var(--gray);
//			}
//		}
//	}
//
//	&-progress {
//		background-color: var(--gray-light) !important;
//	}
//}

.modal {
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(4px);
	padding: 15px;

	&-content {
		border: 0;
	}

	&-dialog {
		height: 100%;
		align-items: center;
		display: flex;
		margin: auto;
	}

	&-body {
		padding: 24px;

		h5 {
			margin-bottom: 15px;
		}
	}

	&-footer {
		border: 0;
		padding: 0 24px 24px;

		.btn {
			font-size: 12px;

			&.outline {
				border: 0;
			}

			&.btn-white {
				border: 1px solid #0095e8 !important;
				background: #fff !important;
				color: #0095e8;
			}
		}
	}
}

.select2-container {
	&--default {
		width: 100% !important;
	}

	.select2-selection {
		background-color: #f5f8fa !important;
	}
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #f5f8fa !important;
	min-height: 42px !important;
}
.select2-container--default.select2-container--focus
	.select2-selection--multiple {
	border: 0 !important;
}

.label-title {
    margin-bottom: 0.5rem;
    font-size: 1.05rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.notAllowed {
    cursor: not-allowed;
}

.disabled {
	pointer-events: none;
    opacity: 0.4;
}

.centered {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 20px auto;
    ::ng-deep img {
        width: 90px;
    }
}

.btn-submit {
    min-height: 51px;
    min-width: 170px;
}
